import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import moment from 'moment'
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
@Directive({
    selector: '[remain]',
    standalone: true,
  })
export class RemainDirective {
    @Input('time') time!: string;
    @Input('format') format:string = 'mm:ss'

    @Output() expire: EventEmitter<boolean> = new EventEmitter<boolean>();

    timer$: Observable<number> = timer(0, 100);
    constructor(
        private elementRef: ElementRef,
    ) {

    }
  
    ngOnInit() {
        const el = this.elementRef.nativeElement
        const remain_x = moment.utc(this.time).unix()

        this.timer$.subscribe(v => {
            const now_x = moment.utc().unix()
            const remain = remain_x - now_x;
            if(remain > 0) {
                el.innerHTML = moment.unix(remain).format(this.format)
            } else {
                const duration = moment.duration("0");
                const a = moment.utc(0).format(this.format)
                // console.log(a)
                // const days = duration.days()
                // const hours = duration.hours();
                // const minutes = duration.minutes();
                // const seconds = duration.seconds();
                // const milliseconds = duration.milliseconds();

                // var date = moment().da.hours(hours).minutes(minutes).seconds(seconds).millisecond(milliseconds);
                // console.log(this.format)

                el.innerHTML = `00d:0h:00m:00s`
                // el.innerHTML = date.format(this.format)
                this.expire.emit(true)
            }
        })
    }
}